// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .p-dialog .p-dialog-header {
    background: #336699;
}

::ng-deep .p-dialog .p-dialog-content {
    background: #336699;
}

::ng-deep .p-dialog .p-dialog-header .p-dialog-title {
    color: #fff;
    font-size: 16px;
}

:host ::ng-deep .p-dialog-header-close-icon {
    color: white;
    font-size: 2rem;
}

.required:after {
    content: " *";
    color: red;
}

.err-highlight {
    color: red;
}

::ng-deep .p-calendar {
    width: 100% !important;
}

::ng-deep .number-field-set-2 {
    width: 245px;
}

:host ::ng-deep .form-control-inputnumber {
    width: 100px;
}

#bond-lc-types-offered-container,
#is-containers {
    border: 1px solid #e3e3e3 !important;
    padding: 5px;
}

.invoice-col-width-1 {
    width: 23%;
}

.invoice-col-width-2 {
    width: 20%;
}

.req-field {
    color: red;
}

.text-left {
    text-align: left;
}

.no-overflow {
    overflow: hidden;
}

.disabledForm {
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/search-bank/edit-bank/edit-bank.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":["::ng-deep .p-dialog .p-dialog-header {\r\n    background: #336699;\r\n}\r\n\r\n::ng-deep .p-dialog .p-dialog-content {\r\n    background: #336699;\r\n}\r\n\r\n::ng-deep .p-dialog .p-dialog-header .p-dialog-title {\r\n    color: #fff;\r\n    font-size: 16px;\r\n}\r\n\r\n:host ::ng-deep .p-dialog-header-close-icon {\r\n    color: white;\r\n    font-size: 2rem;\r\n}\r\n\r\n.required:after {\r\n    content: \" *\";\r\n    color: red;\r\n}\r\n\r\n.err-highlight {\r\n    color: red;\r\n}\r\n\r\n::ng-deep .p-calendar {\r\n    width: 100% !important;\r\n}\r\n\r\n::ng-deep .number-field-set-2 {\r\n    width: 245px;\r\n}\r\n\r\n:host ::ng-deep .form-control-inputnumber {\r\n    width: 100px;\r\n}\r\n\r\n#bond-lc-types-offered-container,\r\n#is-containers {\r\n    border: 1px solid #e3e3e3 !important;\r\n    padding: 5px;\r\n}\r\n\r\n.invoice-col-width-1 {\r\n    width: 23%;\r\n}\r\n\r\n.invoice-col-width-2 {\r\n    width: 20%;\r\n}\r\n\r\n.req-field {\r\n    color: red;\r\n}\r\n\r\n.text-left {\r\n    text-align: left;\r\n}\r\n\r\n.no-overflow {\r\n    overflow: hidden;\r\n}\r\n\r\n.disabledForm {\r\n    pointer-events: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
